import { graphql } from 'gatsby';
import React from 'react';
import { getHref, getImage } from '../../../../utils/sanityTypes';

import Transport from '../../Transport';
import { TransportOptionsSectionProps } from './types';

const TransportOptionsSection: React.VFC<TransportOptionsSectionProps> = ({ title, items }) => (
  <Transport
    data={{
      title: title ?? '',
      items: (items ?? []).map((transport) => {
        const { _key: key, title: transportTitle, image, link } = transport ?? {};

        return {
          key: key ?? '',
          title: transportTitle ?? '',
          image: getImage(image),
          link: { to: getHref(link) },
        };
      }),
    }}
  />
);

export const fragment = graphql`
  fragment TransportOptionsSectionFragment on SanityTransportOptionsSection {
    _key
    _type
    title
    items {
      _key
      title
      link {
        ...LinkFragment
      }
      image {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default TransportOptionsSection;
