import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { TransportImagesProps } from './types';
import * as Styled from './styles';
import { settings } from './consts';
import PostTeaserCard from '../PostTeaserCard';

const TransportImages: React.FC<TransportImagesProps> = ({ items }) => (
  <Styled.TransportImages>
    <Slider {...settings}>
      {items.map(({ key, title, image, link }) => (
        <Styled.TrasnportImagesSlide {...{ key }}>
          {/* TODO - WARNING isSquare */}
          <PostTeaserCard {...{ title, image, link }} isSquare />
        </Styled.TrasnportImagesSlide>
      ))}
    </Slider>
  </Styled.TransportImages>
);

export default TransportImages;
