import styled from 'styled-components';

import { InnerSmall } from '../../styles/Grid/styles';
import Typography from '../../styles/Typography';

export const TransportInner = styled(InnerSmall)``;

export const TrasnportTitle = styled(Typography)``;

export const TransportList = styled.div`
  margin-top: 2.5rem;
`;
