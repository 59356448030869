import styled from 'styled-components';

import { CarouselPaginationDots } from '../../atoms/Carousel/styles';
import media from '../../../common/MediaQueries';
import { FlexBox } from '../../styles/Grid/styles';

export const TransportImages = styled.div`
  .slick-slide {
    visibility: hidden;
  }
  .slick-slide.slick-active {
    visibility: visible;
  }

  ${CarouselPaginationDots} {
    margin-top: 2.5rem;
  }
  @media ${media.phone} {
    .slider {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 3rem);
      margin-left: -1.5rem;
      margin-top: -3rem;
    }
  }
`;

export const TrasnportImagesSlide = styled(FlexBox)`
  @media ${media.phone} {
    width: 21.25rem;
    margin: 3rem 1.5rem 0;
  }
`;
