import React from 'react';

import * as Styled from './styles';
import Image from '../../atoms/Image';
import { PostTeaserCardProps } from './types';
import { LinkMode } from '../../atoms/Link/enums';

const PostTeaserCard: React.FC<PostTeaserCardProps> = ({
  image,
  title,
  date,
  link,
  author,
  isAlt,
  isSquare
}) => (
  <Styled.PostTeaserCard mode={LinkMode.HasPlainLook} {...link} {...{ isAlt, isSquare }}>
    <Styled.PostTeaserCardMedia {...{ isAlt, isSquare }}>
      <Image {...image} />
    </Styled.PostTeaserCardMedia>
    <Styled.PostTeaserCardContent>
      <Styled.PostTeaserCardTitle>{title}</Styled.PostTeaserCardTitle>
      {(author || date) && (
        <Styled.PostTeaserCardSignature variant="textL">
          {author && (
            <>
              by{' '}
              <Styled.PostTeaserCardAuthor>
                {author?.firstName} {author?.lastName}
              </Styled.PostTeaserCardAuthor>
            </>
          )}
          {author && date && <>, </>}
          {date && <Styled.PostTeaserCardDate>{date}</Styled.PostTeaserCardDate>}
        </Styled.PostTeaserCardSignature>
      )}
    </Styled.PostTeaserCardContent>
  </Styled.PostTeaserCard>
);

export default PostTeaserCard;
