import React from 'react';

import { TransportProps } from './types';
import * as Styled from './styles';
import TransportImages from '../../molecules/TransportImages';

const Transport: React.FC<TransportProps> = ({ data }) => {
  const { title, items } = data;

  return (
    <Styled.TransportInner>
      <Styled.TrasnportTitle variant="headingM">{title}</Styled.TrasnportTitle>
      <Styled.TransportList>
        <TransportImages {...{ items }} />
      </Styled.TransportList>
    </Styled.TransportInner>
  );
};

export default Transport;
