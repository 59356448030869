import React from 'react';
import styled, { css } from 'styled-components';

import { positionFullStyles, transition } from '../../../common/mixins';
import { Box, FlexBox } from '../../styles/Grid/styles';
import Typography from '../../styles/Typography';
import Link from '../../atoms/Link';
import { PostTeaserCardStyledProps } from './types';

export const PostTeaserCardMedia = styled(FlexBox)<PostTeaserCardStyledProps>`
  width: 100%;
  height: 100%;

  ${({ isAlt, isSquare }) =>
    !isAlt &&
    !isSquare &&
    css`
      img {
        height: calc(100% - 10.125rem); // Content height
        max-height: calc(100% - 10.125rem); // Content height
      }
    `};
`;

export const PostTeaserCardTitle = styled(Typography).attrs({
  forwardedAs: 'h2',
})`
  height: 3rem;
`;

export const PostTeaserCardSignature = styled(Typography)`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;

export const PostTeaserCardAuthor = styled(Typography).attrs({
  forwardedAs: 'span',
})`
  color: ${({ theme }) => theme.colors.additional.dark};
`;

export const PostTeaserCardDate = styled(Typography).attrs({
  forwardedAs: 'span',
})`
  white-space: nowrap;
`;

export const PostTeaserCardContent = styled(Box)<PostTeaserCardStyledProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 2.5rem;
  border-radius: 0 0 0.25rem 0.25rem;
  z-index: 1;
  color: ${({ theme }) => theme.colors.additional.dark};
  background-color: ${({ theme }) => theme.colors.neutral.neutral10};
  border: 1px solid ${({ theme }) => theme.colors.additional.accentGray3};
  border-top: 0;
`;

export const darkBgStyles = css`
  ${PostTeaserCardContent} {
    background-color: transparent;
    border: 0;
  }
  ${PostTeaserCardContent},
  ${PostTeaserCardAuthor},
  ${PostTeaserCardSignature} {
    color: ${({ theme }) => theme.colors.neutral.neutral10};
  }
`;

export const PostTeaserCard = styled(({ isAlt: _, isSquare: __, ...props }) => <Link {...props} />)`
  overflow: hidden;
  position: relative;
  border-radius: 0.25rem;
  height: 32rem;
  width: 100%;

  ${PostTeaserCardTitle},
  ${PostTeaserCardSignature},
  ${PostTeaserCardMedia} {
    ${transition('transform', '.3s')};
  }

  &:hover {
    ${PostTeaserCardTitle},
    ${PostTeaserCardSignature} {
      transform: translateY(-0.1rem);
    }
    ${PostTeaserCardMedia} {
      transform: scale(1.025);
    }
  }

  ${({ isAlt }) =>
    isAlt &&
    css`
      ${darkBgStyles}
    `}

  ${({ isSquare }) =>
    isSquare &&
    css`
      height: 21.25rem;
      ${darkBgStyles}
      ${PostTeaserCardTitle} {
        height: auto;
      }
    `}

    ${({ isAlt, isSquare }) =>
    (isAlt || isSquare) &&
    css`
      &::after {
        ${positionFullStyles}
        content: '';
        ${({ theme }) => theme.overlays.linear50}
      }
    `}
`;
